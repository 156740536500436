import * as Sentry from '@sentry/nuxt'

const runtimeConfig = useRuntimeConfig()
Sentry.init({
  dsn: runtimeConfig.public.SENTRY_NUXT_DSN,
  environment: runtimeConfig.public.FIRST_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    runtimeConfig.API_URL,
    runtimeConfig.public.NUXT_URL,
    /^\/api\//,
  ],
  
  // Enable Session Replay
  replaysSessionSampleRate: 0.1, // Sample 10% of sessions
  replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors
  
  // Configure Session Replay
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: true, // Block all media (images, videos) by default
      mask: ['.comment', '.sentry-mask', '#editor-content, #simple-editor'],
      networkDetailDenyUrls: [
        '/acp/*',
      ]
    }),
  ],
})
